import dynamic from 'next/dynamic';
import Box  from '@mui/material/Box';
import IconButton from "@mui/material/IconButton"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getBrands, maxString, stripTags} from "../../../config/helpers";
import Typography from '@mui/material/Typography';
import Link from 'next/link';


const HomeArticleImage = dynamic(() => import("./HomeArticleImage"));
const ProductCard = dynamic(() => import("@components/Components/Reusables/ProductCard"));
const StyledBox = dynamic(() => import("@components/Custom/Box/StyledBox"));


const HomeArticleContainer = ({article, index}) => {
	
	const href = getBrands(['EH']) ? `/articles/${article.slug}` : article.url;	

	return (
		<Box key={article.article_id}>
			<Link style={{margin: '0px !important'}} className='fs-16' href={href}>
				<ProductCard 
					contentSx={{
						padding: '6px',
						minHeight:{
							xs: 300,
							md: 425,
							lg: 425,
							xl: 425,
						}, 
						maxHeight: {
							md: 170,
							lg: 176,
							xl: 191,
						}
					}}
					className='mb-2'
					cardActions={
						<>
							<StyledBox className="d-flex mb-1">
								<IconButton className="p-0" color="primary" variant="contained" aria-label="Read Article">
									<NavigateNextIcon />
								</IconButton>
								<Typography className="fw-bold" color="primary">
									Read Full Article
								</Typography>
							</StyledBox>
						</>
					}
				>
					<HomeArticleImage article={article}/>
					
					<Box className="p-1">
						<Typography className="mt-2" gutterBottom variant="h5" component="div">
							{article.title}
						</Typography>

						<Box className='w-100 mt-2 position-reltive' >
							<Typography 
								color="text.secondary"
								dangerouslySetInnerHTML={{
									__html: maxString(stripTags(article.content), 300),
								}}
							/>
						</Box>
					</Box>

				</ProductCard>
			</Link>

		</Box>
	)
}

export default HomeArticleContainer